import React from 'react';
import {navigate} from 'gatsby';
import {Redirect} from '@reach/router';
import SUBSCRIPTION from 'modules/user/graphql/subscription.graphql';
import INVOICES from 'modules/user/graphql/invoices.graphql';
import {useQuery} from '@apollo/client';
import {
  Container,
  Button,
  StyledLink,
  StyledLink2,
  LinksContainer,
  TitleTable,
  DataTable,
  Line,
} from 'modules/subscription/components/AfterPayement/styles';
import LoadingIndicator from 'modules/common/LoadingIndicator';
import successfulOrder from 'modules/subscription/icons/successful-order.svg';

const Success = () => {
  const {data, loading} = useQuery(SUBSCRIPTION);
  const subscriptionData = data?.recurrentPayment;

  if (loading) return <LoadingIndicator />;

  if (subscriptionData?.status === 'FAILED') {
    return <Redirect noThrow to="/subscription/failed" />;
  }

  if (!subscriptionData || subscriptionData?.status !== 'ACTIVE') {
    return <Redirect noThrow to="/subscription" />;
  }

  const {status, expiresAt, paymentMethod} = subscriptionData;

  const lastInvoice = subscriptionData?.invoices?.edges[0]?.node;
  const payedWith =
    paymentMethod?.name === 'PAYPAL' ? 'باي بال' : 'البطاقة البنكية';
  const fromLink = window.history.state?.fromLink;

  return (
    <Container>
      <img src={successfulOrder} alt="successfull-order" />
      <h3>تمت عملية الاشتراك بنجاح</h3>
      <div>شكرا لاشتراكك في برمج .</div>
      <p>
        يمكنك الآن بدء رحلتك في عالم برمج و الاستمتاع في الحصول على 7 أيام
        تجريبة مجانية{' '}
      </p>

      <StyledLink to={fromLink || '/tracks'}>ابدأ رحلتك الان</StyledLink>

      <Line />
      <table>
        <tbody>
          <tr>
            <TitleTable>التاريخ</TitleTable>
            <DataTable>
              {new Date(expiresAt)?.toLocaleDateString('ar', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              })}
            </DataTable>
          </tr>
          <tr>
            <TitleTable>تم الحجز بواسطة</TitleTable>
            <DataTable>{payedWith}</DataTable>
          </tr>
          <tr>
            <TitleTable>الحالة</TitleTable>
            <DataTable>تم الحجز</DataTable>
          </tr>
          <tr>
            <TitleTable>رقم العملية</TitleTable>
            <DataTable>{lastInvoice?.invoiceId}</DataTable>
          </tr>
          <tr>
            <TitleTable>المجموع</TitleTable>
            <DataTable>{lastInvoice?.totalPrice} دولار</DataTable>
          </tr>
        </tbody>
      </table>
    </Container>
  );
};

export default Success;
